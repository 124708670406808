<template>

      <div  
            v-if="event.loading">
            <p>
              Laden {{ vertaal ( 'site_naam' ) }}
            </p>

      </div>

      <div 
          v-else 
          class="content"
          >

                     <div 
                            v-if="event.aantal_beelden > 0 "
                            class="fotoblok">


                                     <div 
                                                     v-for="(value_beeld, key_beeld) in event.beeld" 
                                                     :key="key_beeld" 
                                                    class="foto_blokje"
                                                >

                                                <ImageItem 

                                                         :source="value_beeld.img"  
                                                         bijschrift=""  
                                                />

                                        </div>
                             </div>


                     </div>



</template>



<script>

/*
                                                            {{event.beeld_nr}}

                          <ImageItem 
                                   :source="event.beeld [ event.beeld_nr ].img"  
                                   :bijschrift="event.beeld[ event.beeld_nr ].tekst [ event.taalkeuze ] !== null  ? event.beeld[ event.beeld_nr ].tekst [ event.taalkeuze ] : '' "  
                          />



          <ImageItem 
                   source="http://baslobik.local/app/src/assets/f1a.jpg"  
                   bijschrift="test"  
            />



                                                <ImageItem 
                                                         :source="value_beeld.img"  
                                                         :bijschrift="value_beeld.tekst [ event.taalkeuze ] !== null  ? value_beeld.tekst [ event.taalkeuze ] : '' "  
                                                />
*/



import ImageItem from '@/components/ImageItem.vue'




import { reactive, computed }  from 'vue';
import store from '@/store'


export default {

    name: 'Atelier',

    // props: {

    //       //  kop: {      
    //       //      // required: true,     
    //       //       type: String,
    //       //       default: "",    
    //       // },

    //       //  doelgroep: {      
    //       //      // required: true,     
    //       //       type: String,
    //       //       default: "0",    
    //       // },

    // },

    components: {
            ImageItem
    },



    setup ( ) {

        const event = reactive ({

                tekst_id: 0,
                beeld_nr: 0,
                tekst_nr: 2,


               // aantal_beelden: 0,


                taalkeuze: computed (() => { 

                          return  store.getters.getTaalKeuze();
                }),



               tekst: computed (() => { 

                       let a =  store.getters.getTekst( event.tekst_nr );

                       //console.log ( "tekst: "  + a );


                       if ( a == null ) return  { 'naam' : 'loading' , 'body' : 'loading' };

                       return a;
                }),


               beeld: computed (() => { 

                       let a =  store.getters.getTekst ( event.tekst_nr );

                       //console.log ( "tekst: "  + a );
                    //   event.aantal_beelden = 0;

                       if ( a === undefined ) return null;

                      //console.log( "setTekstData: "  + JSON.stringify ( a ) )

                       if ( a.beeld  === undefined ) return null;
                       if ( a.beeld [ 0 ] === undefined ) return null;

                       return a.beeld;
                }),


               aantal_beelden: computed (() => { 

                       let a =  store.getters.getTekst ( event.tekst_nr );

                       //console.log ( "tekst: "  + a );
                    //   event.aantal_beelden = 0;

                       if ( a === undefined ) return null;
                       if ( a.beeld  === undefined ) return null;

                       let t = a.beeld.length ;


                       return t;
                }),





              loading: computed (() => { 

                      return ( store.getters.getTekstStatus ()  ) 
              }), 



        });


        function wisselBeeld (  i )
        {
              //alert ( i );

                if ( i < 0 )
                {
                        i = event.aantal_beelden - 1;
                }

                if ( i >= event.aantal_beelden )
                {
                      i = 0;
                }

                event.beeld_nr = i ;
        }




         function setTekstKeuze ( data ) 
         {
                  event.tekst_id     = data.id;
                 // event.tekst            = data.body [ store.taalKeuze ];
         }


        function vertaal ( code ) {

                return store.getters.getVertaling ( code );
        }



         return { 
                event, 
                vertaal,
        //        laadProjectTekst, 
                setTekstKeuze ,
                wisselBeeld,
              };
     },




      mounted() {

                 store.dispatch ( 'setMenuStatus', 'close' );
           //     this.laadProjectTekst();
      },


      updated() {

             //   this.laadProjectTekst();
      },

}



</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >






.fotoblok 
{
       margin:  1rem ;
}

.foto_blokje 
{
            margin: 0 0 1rem 0;
}

.fotoblok img
{
      max-width: 100%;
}




@media (min-width: 25rem) 
{
          .fotoblok 
          {
                margin:  0 1rem ;

                 display:  grid;

          /*       grid-template-columns: repeat( auto-fit, minmax( 200px, 500px ));*/

                 grid-template-columns: 1fr 1fr ;
                 grid-gap: 2rem;

/*                                  background-color: rgb( 90, 0, 180 );
*/
          }

          .foto_blokje 
          {
                      margin: 0 ;
          }
}


@media (min-width: 35rem) 
{

          .fotoblok 
          {
                 margin:  0 2rem ;

                 grid-template-columns: 1fr 1fr 1fr;
                 grid-gap: 2rem;
          }

}




@media (min-width: 50rem) 
{

          .fotoblok 
          {
                 grid-template-columns: 1fr 1fr 1fr 1fr;
                 grid-gap: 2rem;

/*                 background-color: rgb( 90, 0, 180 );
*/          }


/*.fotoblok img
{
      filter: grayscale(40%);
}*/


/*
.fotoblok img:hover
{
      filter: grayscale(0);
}*/


}




@media (min-width: 80rem) 
{

          .fotoblok 
          {
                 grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                 grid-gap: 2rem;
          }

}






</style>
