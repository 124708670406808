<template>
    <Atelier  />
</template>



<script>


import Atelier from '@/components/Atelier.vue'


import store   from '@/store' ;



export default {

      name: 'PaginaAtelier',

      components: {

              Atelier
     },



    setup ( ) {

        // const event = reactive ({


        // });

            function initPaginaData () {

                    store.dispatch ( 'setMenuStatus', 'close' );   
                     store.dispatch ( 'laadVertaling' );   
                    store.dispatch ( 'laadTekst' );   

                     //-----------------------------------//

                     let t = "Bas Lobik Atelier";

                     if ( store.state.vertaalLijst.length > 0 ) {

                            t =  store.getters.getVertaling ( "pagina_atelier_titel" );
                     }  

                     document.title = t;


                     //-----------------------------------//

            }


            return { 
                  // event, 
                   initPaginaData,

            };
       },


        beforeMount() {

                  this.initPaginaData();
        },


          mounted () {

               //  console.log( "Rubriek: mounted" );

                 this.initPaginaData();
          },


          updated () {

               this.initPaginaData();

             //  window.scrollTo(0,0);
          },

}

</script>